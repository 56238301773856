// vue
import { ref } from 'vue'

// nuxt
import { useNuxtApp } from '#app'

// pinia
import { defineStore } from 'pinia'

// types
import type {
  LeadSource,
  Nullable
} from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

export const usePendingSchoolStore = defineStore('pending-school', () => {
  // fetch repositories
  const { $pendingSchools } = useNuxtApp()

  // state
  const pendingSchool = ref<Nullable<LeadSource>>(null)

  // actions
  async function show (
    id: number,
    config?: NitroFetchOptions<string>
  ) {
    const { pendingSchool: _pendingSchool } =
      await $pendingSchools.show<{ pendingSchool: LeadSource }>(id, config)
    pendingSchool.value = _pendingSchool
    return pendingSchool
  }

  async function create (
    payload: Record<string, any> | undefined,
    config?: NitroFetchOptions<string>
  ) {
    const { pendingSchool: _pendingSchool } =
      await $pendingSchools.create<{ pendingSchool: LeadSource }>(
        payload,
        config
      )
    pendingSchool.value = _pendingSchool
    return pendingSchool
  }

  return {
    create,
    show,
    pendingSchool
  }
})
